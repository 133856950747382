
export default async function loadModule(a,b)
{
    checkVal(a,b)
}

function checkVal(a,b) {
    let keysA = Object.keys(a),
        keysB = Object.keys(b)

        const compareArrays = (a, b) => {
            b.forEach(e => {
                if(a.indexOf(e) === -1){
                    console.warn(e+" not exist's in default locale, removing it from object")
                    delete b[e];
                } 
            });
          };
    compareArrays(keysA,keysB);
    Object.entries(a).map(([key,val])=>{
        if(b[key] === undefined)
        {
            b[key] = val;
            return null;
        }
        if (typeof val === "object")
        {
            if(!Array.isArray(val))
            {
                checkVal(val,b[key]);
            }
        }
        return null;
    })
}